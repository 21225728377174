import { SearchIcon } from '@heroicons/react/solid'
import React, { useState, useEffect, Fragment, forceUpdate } from 'react';
import { CloudDownloadOutline } from 'heroicons-react';

import { Line } from 'react-chartjs-2';

/* This example requires Tailwind CSS v2.0+ */
function randomNumber(min, max) {

    var rand = min + Math.random() * (max - min);
    return Math.round(rand)

}


export default function BatchGraph(props) {
    const [searchBatch, setSearchBatch] = useState("")





    // useEffect(() => {

    //     fetch("https://api.pacman.ai/getTeamNames")
    //         .then(response => response.json())
    //         .then((jsonData) => {
    //             // jsonData is parsed json object received from url
    //             console.log(jsonData)

    //             var teamList = [];
    //             var teamSize = 0;
    //             for (var teamName in jsonData.teamData) {
    //                 teamList.push(jsonData.teamData[teamName]);
    //                 teamSize += 1
    //             }
    //             for (var i = 0; i < 100000; i++) {
    //                 teamList.push({
    //                     teamName: String(Math.random()),
    //                     numberOfGamesPlayed: String(Math.random()),
    //                     numberOfWins: String(Math.random())
    //                 })

    //             }
    //         })
    //         .catch((error) => {
    //             // handle your errors here
    //             console.log("error")
    //             console.error(error)
    //         })

    //     // if(teamPageNumber === 0){
    //     //   setTeams(allTeams.slice(0, 6));
    //     // }else{
    //     //   setTeams(allTeams);
    //     // }

    //     // console.log(allTeams)
    // }, [])

    //   useEffect(() => {
    //     console.log(searchContents)
    //     console.log(teams)
    //     console.log(teamPageNumber)
    //     console.log(teamSelectionSize)
    //     console.log('-----')
    //   }, [searchContents, teams, teamPageNumber, teamSelectionSize]);


    function search(e) {
        const sTime = Date.now();
        var selectedName = e.target.value
        setSearchBatch(selectedName);


        var teamList = [];
        var teamSize = 0;
        // for (var teamName in allTeams) {

        //   if(String(allTeams[teamName].teamName).toLowerCase().includes(selectedName.toLowerCase())){
        //     teamList.push(allTeams[teamName]);
        //     teamSize+=1;
        //   }
        // }

    }
    const randTagNumber = randomNumber(1337, 9337)
    const randBatchNumber = randomNumber(12345678, 92345678)

    var dataset = []
    for(var i =0; i < 9; i++){
        // LINE COLORS
        var bgColor = ""
        var bgaColor = ""
        if(i == 0){
            bgColor = `rgb(43, 0, 255)`
            bgaColor = `rgba(43, 0, 255, 0.2)`
        }else if(i == 1){
            bgColor = 'rgb(8, 0, 255)'
            bgaColor = `rgba(8, 0, 255, 0.2)`

        }else if(i == 2){
            bgColor = 'rgb(41, 44, 255)'
            bgaColor = `rgba(41, 44, 255, 0.2)`

        }else if(i == 3){
            bgColor = 'rgb(203, 207, 212)'
            bgaColor = `rgba(203, 207, 212, 0.2)`

        }else if(i == 4){
            bgColor = 'rgb(191, 195, 199)'
            bgaColor = `rgba(191, 195, 199, 0.2)`

        }else if(i == 5){
            bgColor = 'rgb(186, 190, 194)'
            bgaColor = `rgba(186, 190, 194, 0.2)`

        }else if(i == 6){
            bgColor = 'rgb(255, 33, 33)'
            bgaColor = `rgba(255, 33, 33, 0.2)`

        }else if(i == 7){
            bgColor = 'rgb(245, 61, 61)'
            bgaColor = `rgba(245, 61, 61, 0.2)`

        }else if(i == 8){
            bgColor = 'rgb(247, 82, 82)'
            bgaColor = `rgba(247, 82, 82, 0.2)`

        }

        // BORDER COLORS
        dataset.push({
            label: 'Tag #' + String(randTagNumber+i),
            data: [
                randomNumber(300, 300), 
                randomNumber(312, 420), 
                randomNumber(302, 510), 
                randomNumber(820,1080), 
                randomNumber(730, 490), 
                randomNumber(1400, 1550), 
                randomNumber(1150, 600), 
                randomNumber(623,420),
                randomNumber(623,1000),
                randomNumber(700,1300),
                randomNumber(623,420),
                randomNumber(623,420)],
            fill: false,
            backgroundColor: bgColor,
            borderColor: bgColor,
          });
    }

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: dataset,
      };
      
      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };

    return (
        <div className="p-10">
            < div className="text-3xl font-semibold text-gray-900 py-2">
                Strut Graph Example using ReactJS, GraphJS and Cloudflare Pages
            </div>
            <div class="border border-gray-200 shadow-xl bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                <div class="px-4 py-5 sm:px-6">
                    {/* // SEARCH CONTENT */}
                    <div className="grid grid-cols-3 gap-4">
                        < div className="text-left text-2xl font-semibold text-gray-900">
                            Batch #{randBatchNumber} Info...
                        </div>
                        <div className="col-start-3">
                            <div className="relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="batchNumber"
                                    id="batchNumber"
                                    className="overflow-ellipsis focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Enter Batch Number"
                                    onChange={search.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* // SEARCH CONTENT */}
                </div>
                <div class="px-4 py-5 sm:p-6">

                    {/* // MAIN TEAM SELECTION CONTENT */}
                    <div className="max-h-full">

                    <Line data={data} options={options} />



                    </div>
                    {/* // MAIN TEAM SELECTION CONTENT */}
                </div>
                {/* // PAGINATION CONTENT */}


                {/* // PAGINATION CONTENT */}
            </div>
        </div>
    )

}
