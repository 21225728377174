import logo from './logo.svg';
import './App.css';

import BatchGraph from './components/batchGraph';

function App() {
  return (
    <div className="App">
      <BatchGraph />
    </div>
  );
}

export default App;
